import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import SurveyDefinition from './components/SurveyDefinition';
import SurveyDefinitions from './components/SurveyDefinitions';
import SurveySchedules from './components/SurveySchedules';
import './custom.css'

//toasts
//npm install --save react-toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//toast
//  see https://fkhadra.github.io/react-toastify/introduction/
// const notify = () => toast("Hello world");
// const notify2 = (message: string) => toast(message);


export default () => (

    <span>
        <Layout>
            <Route exact path='/' component={SurveyDefinitions} />
            <Route path='/surveyDefinition' component={SurveyDefinition} />
            <Route path='/surveySchedules' component={SurveySchedules} />
        </Layout>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />

    </span>
);



import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';


export function LoginFail() {

    //shows the list of items
    return (
        <div className='width100pct'>
            You failed to authenticate successfully. 
        </div>
    );
}

export default connect()(LoginFail);

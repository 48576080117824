import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';

import $ from 'jquery'

//icons
//  see https://react-icons.github.io/react-icons/icons?name=md
import { MdClose } from "react-icons/md";
import { SelectedRecProps } from './SurveyDefinitions';

export interface ScheduleRec {
    id: string;    
    scheduleName: string;
    linkedTo: string;    
    description: string;
    createByUserID: string;    
    createDate: string;
    lastChanged: string;
    lastChangedByID: string;
    surveyList: string
}

var _props: SelectedRecProps;
//close button handler
const closeSurveyHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log('close schedules button clicked');
    //alert('close survey button clicked');
    _props.refreshList('close schedules button clicked');
  };

export function SurveySchedules(props: SelectedRecProps) {
    _props = props;
    if (props.mode == 'hidden') {
        //SurveyDefinition component is hidden
        return (
            <div></div>
        )
    }
    var url = process.env.REACT_APP_SURVEY_FUNCTIONCODE_LISTSCHEDULES;
    var ar: any = GetJsonResult(url);  //an array of objects

    //handle when the schedules is closed
    const refreshList = ():void => {
        alert('SurveySchedules.refreshList() called');
    }

    //shows the list of items
    return (
        <div className='width100pct'>
            <table width='100%'>
                <tbody>
                    <tr>
                        <td className='leftAlign' >Survey Schedules</td>
                        <td className='rightAlign'><button onClick={closeSurveyHandler}><MdClose/></button></td>
                    </tr>
                </tbody>
            </table>
            
            
            <table className='table table-striped' aria-labelledby="tabelLabel">
            <thead>
                <tr>
                <th>Schedule</th>
                <th>Linked To</th>
                <th>Description</th>
                <th>Surveys</th>
                </tr>
            </thead>
            <tbody>
            {ar.map((rec: ScheduleRec) =>
                <tr key={rec.id}>
                <td>{rec.scheduleName}</td>
                <td>{rec.linkedTo}</td>
                <td>{rec.description}</td>
                <td>{rec.surveyList}</td>
                </tr>
            )}
            </tbody>
            </table>
            <hr></hr>
        </div>
    );
}

function GetJsonResult(url: any) {
    //NOTE: this should return an array of objects
    var result;
    $.ajax({
        method: "GET",
        async: false,
        url: url,
        dataType: "json"
    })
        .done(function (msg) {
            result = msg;
            return result;
        });

    return result;
}



export default connect()(SurveySchedules);

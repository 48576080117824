import React, { Component } from "react";
import $ from "jquery";

import * as SurveyAnalytics from "survey-analytics";
import "survey-analytics/survey.analytics.css";

import * as SurveyAnalyticsTabulator from "survey-analytics/survey.analytics.tabulator";
import "tabulator-tables/dist/css/tabulator.css";
import "survey-analytics/survey.analytics.tabulator.css";

import { Model } from "survey-core";

import { SurveyDefinitionRec, SelectedRecProps } from './SurveyDefinitions';
import { MdClose } from "react-icons/md";
//import { SurveyPDF } from "survey-pdf";

//react script tag for referencing external lib   
//  https://stackoverflow.com/questions/53396307/how-do-i-use-external-script-that-i-add-to-react-js

//download xlsx
//<script type="text/javascript" src="https://oss.sheetjs.com/sheetjs/xlsx.full.min.js"></script>
//download pdf
{/* <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.5/jspdf.min.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jspdf-autotable/2.3.2/jspdf.plugin.autotable.js"></script> */}

//sample from https://codesandbox.io/s/u5y4pn?file=/src/SurveyAnalyticsComponent.jsx:0-1272

//export to excel 
//  surveyjs doesn't do this, and Tabulator is 3rd party
//  see http://tabulator.info/docs/4.0/download

//export to pdf
//npm install survey-pdf --save
//see https://surveyjs.io/Documentation/Pdf-Export?id=get-started-react

//tabulator as a tsx file, see https://surveyjs.answerdesk.io/ticket/details/t8085/how-to-use-tabulator

//close button handler
const closeSurveyHandler = (event) => {
    event.preventDefault();
    console.log('close survey button clicked');
    //alert('close survey button clicked');
    _props.refreshList('close survey responses table button clicked');
  };

const buildUrlReplace = (url, searchFor, replaceWith) => {
    var s = url;
    s = s.replace(searchFor, replaceWith)
    return s;
}

var _props;
class SurveyAnalyticsComponent extends Component {

    constructor(props) {
        _props = props;
        super();
    }

    handleChange(e) {
        setName(e.target.value);
      }

          //For props, you use componentDidUpdate
    componentDidUpdate(prevProps){
        //You must have an if check, or loop forever
        if (this.props.id !== this.prevProps.id){
            //do something like make an API call
            //perhaps set this on state for display
            _props = this.props;
        }
   
    }

    componentDidMount() {
        //excel download
        const script = document.createElement("script");
        script.src = "https://oss.sheetjs.com/sheetjs/xlsx.full.min.js";
        script.async = true;
        //script.onload = () => this.scriptLoaded();     
        document.body.appendChild(script);

        //NOTE: not able to get pdf download working yet, get a jspdf error
        //      see https://surveyjs.io/Documentation/Pdf-Export?id=get-started-react   *******
        //pdf download
        // const script2 = document.createElement("script");
        // script2.src = "https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.5/jspdf.min.js";
        // script2.async = true;
        // //script2.onload = () => this.scriptLoaded();     
        // document.body.appendChild(script2);

        // //pdf download
        // const script3 = document.createElement("script");
        // script3.src = "https://cdnjs.cloudflare.com/ajax/libs/jspdf-autotable/2.3.2/jspdf.plugin.autotable.js";
        // script3.async = true;
        // //script3.onload = () => this.scriptLoaded();     
        // document.body.appendChild(script3);

        var baseUrl = process.env.REACT_APP_SURVEY_FUNCTIONCODE_GETDEFINITION;
        var url = buildUrlReplace(baseUrl, "{id}", _props.id);

        $.ajax({
            method: "GET",
            async: false,
            url: url, 
            dataType: "json"
            })
            .done(function (defn) {
                //json is the data read from the file json.js

                //this is the survey definition
                var survey = new Model(defn.surveyDetail);

                //var survey = new Model(json);
                survey.haveCommercialLicense = 'true';

                //var allQuestions = survey.getAllQuestions();

                var panel1Node = document.getElementById("vizPanel1");
                panel1Node.innerHTML = "";

                //these are the survey responses
                //sample gets data from https://api.surveyjs.io/private/Surveys/getSurveyNPCResults/
                //NOTE: data from the example is in json format
                baseUrl = process.env.REACT_APP_SURVEY_FUNCTIONCODE_GETRESPONSES;
                url = buildUrlReplace(baseUrl, "{id}", _props.id);

                $.ajax({
                    method: "GET",
                    async: false,
                    url: url,
                    dataType: "json"
                    })
                    .done(function (data) {

                        var surveyAnalyticsTabulator = new SurveyAnalyticsTabulator.Tabulator(
                            survey,
                            data.Data,
                            {
                                haveCommercialLicense: true,
                                downloadButtons: ["xlsx", "csv"],
                             }
                        );
                        
                        
                        
                        surveyAnalyticsTabulator.render(panel1Node);
                        $("#loadingIndicator1").hide();
      
                    });
            }
        )
        
    }

 


    render() {
        return (
            <div>
                {/* <ScriptTag isHydrating={false} type="text/javascript"  src="https://oss.sheetjs.com/sheetjs/xlsx.full.min.js" /> */}
                {/* <ScriptTag isHydrating={false} type="text/javascript"  src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.5/jspdf.min.js" />
                <ScriptTag isHydrating={false} type="text/javascript"  src="https://cdnjs.cloudflare.com/ajax/libs/jspdf-autotable/2.3.2/jspdf.plugin.autotable.js" /> */}

                <table className="width100pct">
                    <tbody>
                        <tr>
                            <td>
                                <h2>Survey Responses</h2>
                            </td>
                            <td className="rightAlign">
                                <button onClick={closeSurveyHandler}><MdClose/></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <div id="surveyResult"></div>
                <div id="vizPanel1"></div>

                <hr></hr>
                <div id="surveyDefinition"></div>



            </div>
        );
    }



}

export default SurveyAnalyticsComponent;

import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { stringify } from 'querystring';

function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function buildUrlReplace(url: any, searchFor: any, replaceWith: any) {
    var s = url;
        s = s.replace(searchFor, replaceWith)
    return s;
}
export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false,
        // logo: Array = []
    };

    public render() {
        // const data = this.state.logo[0];
        var url4 = String(process.env.REACT_APP_SURVEY_FUNCTIONCODE_GETLOGO);
        var USER_ID =  getParameterByName("id",  window.location.href);
        url4 = buildUrlReplace(url4, "{id}", USER_ID);
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container>
                        <ul>
                    <img src={url4} alt="image"></img>
                    
                    <NavbarBrand tag={Link} to="/"><h1>Survey Creator</h1></NavbarBrand>
                    </ul>
                        {/* <NavbarToggler onClick={this.toggle} className="mr-2"/>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                        <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/surveySchedules">Scheduling</NavLink>
                                </NavItem>
                            </ul>                            

                        </Collapse> */}
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

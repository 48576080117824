import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';

import $ from 'jquery';

//icons
//  see https://react-icons.github.io/react-icons/icons?name=md
import { MdBuild } from "react-icons/md";
import { MdLibraryAdd } from "react-icons/md";
import { MdBarChart } from "react-icons/md";
import { MdTableRows } from "react-icons/md";

import { MdCallMade } from "react-icons/md";
import { MdSchedule } from "react-icons/md";
import { MdOutlineMarkEmailUnread } from "react-icons/md";



import SurveyDefinition from './SurveyDefinition';
import SurveyResponsesTable from './SurveyResponsesTable';
import SurveyResponsesAnalyze from './SurveyResponsesAnalyze';
import SurveySchedules from './SurveySchedules';
import TicketRecipientResults from './TicketRecipientResults';
import LoginFail from './LoginFail';


//npm install react-icons --save
//npm install jquery --save

//http://localhost:3000?id=A3831F4B-EBBE-4F05-BF75-546AC0B37CD2&codeid=9C83BB85-3B64-4C94-A3B3-EA9837B16EB0


var SURVEY_DEFINITION_ID = '';  //has a survey been selected by the user
var USER_ID: any = '00000000-0000-0000-0000-000000000000';

export interface SurveyDefinitionRec {
    createDate: string;
    lastChanged: string;
    surveyDetail: string;
    surveyName: string;
    createByUserID: string;
    id: string;
    lastChangedByID: string;
    portalID: string;
    portalName: string;
    numRecs: number;
    numSurveysSent: number;
    customSubject: string;
    customBody: string;
    customBodyHtml: string;
    scheduleID: string;
    scheduleName: string;
    repetition: number;
    aliasID: string;            //put in the url
    defaultOnDemand: boolean
}

export interface SelectedRecProps {
    id: string,
    mode: string,
    userID: '00000000-0000-0000-0000-000000000000'
    refreshList: (params: any) => void
}

var currentItem : SelectedRecProps = {
    id: '', 
    mode: 'hidden',
    userID: USER_ID,
    refreshList: function (params: any): void {
        alert('refreshlist called back');
        //throw new Error('Function not implemented.');
    }
};



export interface SurveyUsersRec {
	createDate: Date,
	pID: number,
	userID: string,
	company: string,
	emailAddress: string,
	firstName: string,
	lastName: string,
	refID: string,
	roleType: string,
	userRole: string,
}

var blankUser = {
    userID: '',
    emailAddress: '',
    pID: -42,
    roleType: ''
}

var _baseUrl = process.env.REACT_APP_SURVEY_FUNCTIONCODE_GETCREATORLOGIN;
var _baseUserUrl = process.env.REACT_APP_SURVEY_FUNCTIONCODE_GETUSER;
var _user: SurveyUsersRec;

export function SurveyDefinitionsList() {
    //check to see if the user used a link that is valid
   //"?id=A3831F4B-EBBE-4F05-BF75-546AC0B37CD2&codeid=9C83BB85-3B64-4C94-A3B3-EA9837B16EB0"
   const path = window.location.search; //.pathname;
   if (path.length > 0) {
        //var userID =  getParameterByName("id",  window.location.href);
        USER_ID =  getParameterByName("id",  window.location.href);
        currentItem.userID = USER_ID;

        var codeID =  getParameterByName("codeid",  window.location.href);
       
        //var url11 = buildUrlReplace(_baseUrl, "{id}", userID);
        var url11 = buildUrlReplace(_baseUrl, "{id}", USER_ID);
        var url12 = buildUrlReplace(url11, "{codeid}", codeID);
        var result = GetResult(url12);
        if (result == '00000000-0000-0000-0000-000000000000') {
            currentItem.id = '';
            currentItem.mode = 'loginfail';          
        }

   }
   else {
        currentItem.id = '';
        currentItem.mode = 'loginfail';
   }



    //passing properties to another component
    //  see https://www.geeksforgeeks.org/how-to-send-state-props-to-another-component-in-react-with-onclick/
    //passing data from child to parent
    //  see https://javascript.plainenglish.io/passing-data-from-child-to-parent-component-in-typescript-react-92ab6d03ceb1

    //TODO: this is quick and dirty, do this a better way
    //NOTE: this assumes surveydefinitionid is 1st in the query list
    //var id = SURVEY_DEFINITION_ID;
    var surveyHostUrl = process.env.REACT_APP_SURVEY_FUNCTIONCODE_SURVEYHOST;

    var url4 = process.env.REACT_APP_SURVEY_FUNCTIONCODE_LISTDEFINITIONS;
    var url42 = buildUrlReplace(url4, "{id}", USER_ID);
    var ar: any = GetJsonResult(url42);  //an array of objects
 
    //https://www.newline.co/@bespoyasov/how-to-use-state-in-react-components-with-typescript--625c1f27
    const [state, setstate] = useState({id:'', mode:'hidden', userID: USER_ID, user: blankUser})

    const SurveyDefinitionChild = ({id, mode, userID}: SelectedRecProps) => {
        return (
           <SurveyDefinition id={id} mode={mode} userID={userID} refreshList={refreshList}/>
        )
      }

      const SurveyResponsesTableChild = ({id, mode, userID}: SelectedRecProps) => {
        return (
           <SurveyResponsesTable id={id} mode={mode} userID={userID} refreshList={refreshList}/>
        )
      }   
      
      const SurveyResponsesAnalyzeChild = ({id, mode, userID}: SelectedRecProps) => {
        return (
           <SurveyResponsesAnalyze id={id} mode={mode} userID={userID} refreshList={refreshList}/>
        )
      } 
      
      const SurveySchedulesChild = ({id, mode, userID}: SelectedRecProps) => {
        return (
            <SurveySchedules id={id} mode={mode} userID={userID} refreshList={refreshList}/>
        )
      } 

      const TicketRecipientResultsChild = ({id, mode, userID}: SelectedRecProps) => {
        return (
            <TicketRecipientResults id={id} mode={mode} userID={userID} refreshList={refreshList}/>
        )
      } 
      
      
    //button click handler
    const selectSurveyHandler = (event: any, selectedID: string) => {  
        console.log(event);
        console.log(selectedID);

        SURVEY_DEFINITION_ID = selectedID;

        //change the state
        currentItem.id = selectedID;
        currentItem.mode = 'selected';
        setstate({id:currentItem.id , mode:currentItem.mode, userID: currentItem.userID, user: state.user}); 

       };

    //button click handler for viewTicketRecipientResultsHandler
    const viewTicketRecipientResultsHandler = (event: any, selectedID: string) => {  
        console.log(event);
        console.log(selectedID);

        SURVEY_DEFINITION_ID = selectedID;

        //change the state
        currentItem.id = selectedID;
        currentItem.mode = 'viewticketrecipientresults';
        setstate({id:currentItem.id , mode:currentItem.mode, userID: currentItem.userID, user: state.user}); 

       };
    //button click handler
    const viewSurveyResponsesHandler = (event: any, selectedID: string) => {  
        console.log(event);
        console.log(selectedID);

        SURVEY_DEFINITION_ID = selectedID;

        //change the state
        currentItem.id = selectedID;
        currentItem.mode = 'viewresponses';
        setstate({id:currentItem.id , mode:currentItem.mode, userID: currentItem.userID, user: state.user}); 

       };

     //button click handler
     const analyzeSurveyHandler = (event: any, selectedID: string) => {  
        console.log(event);
        console.log(selectedID);

        SURVEY_DEFINITION_ID = selectedID;

        //change the state
        currentItem.id = selectedID;
        currentItem.mode = 'analyzeresponses';
        setstate({id:currentItem.id , mode:currentItem.mode, userID: currentItem.userID, user: state.user}); 
        //alert('Analysis under constuction');

       };      

    //button click handler
    const newSurveyHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        //event.preventDefault();
        console.log('new survey button clicked');
        currentItem.id = '';
        currentItem.mode = 'new';
        setstate({id:currentItem.id, mode:currentItem.mode, userID: currentItem.userID, user: state.user}); 
      };

    //button click handler
    //  fill out and save a survey
    const openSurveyReactHost = (event: React.MouseEvent<HTMLButtonElement>, selectedID: string) => {
        var urlHost = surveyHostUrl + selectedID;
        window.open(urlHost, "_blank");
    }

    //button click handler
    const openScheduleList = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log('schedules button clicked');
        currentItem.id = '';
        currentItem.mode = 'showscheduleslist';
        setstate({id:currentItem.id, mode:currentItem.mode, userID: currentItem.userID, user: state.user}); 
    }

    //handle when the survey detail is closed
    const refreshList = ():void => {
        //alert('SurveyDefinitions.refreshList() called');
        currentItem.id = '';
        currentItem.mode = 'hidden';
        setstate({id:currentItem.id, mode:currentItem.mode, userID: currentItem.userID, user: state.user}); 
    }

    useEffect(() => {
        var urlUser1 = buildUrlReplace(_baseUserUrl, "{id}", USER_ID);
        var result: any = GetJsonResult(urlUser1);          
        _user = result;

        setstate({id:currentItem.id, mode:currentItem.mode, userID: currentItem.userID, user: _user}); 
      }, []);


    //if the user is viewing a particular survey, hide the list
    //   so if currentItem.mode != 'hidden', hide the list
    if (currentItem.mode == 'viewresponses') {
        return (
        <div> 
            <SurveyResponsesTableChild id={currentItem.id} mode={currentItem.mode} userID={currentItem.userID} refreshList={refreshList}></SurveyResponsesTableChild>
        </div>
        );
        }
    else if (currentItem.mode == 'analyzeresponses') {
        return (
        <div> 
            <SurveyResponsesAnalyzeChild id={currentItem.id} mode={currentItem.mode} userID={currentItem.userID} refreshList={refreshList}></SurveyResponsesAnalyzeChild>
        </div>
        );
    }
    else if (currentItem.mode == 'showscheduleslist') {
        return (
        <div> 
            <SurveySchedulesChild id={currentItem.id} mode={currentItem.mode} userID={currentItem.userID} refreshList={refreshList}></SurveySchedulesChild>
        </div>
        );
    }   

     
    else if (currentItem.mode == 'viewticketrecipientresults') {
        return (
        <div> 
            <TicketRecipientResultsChild id={currentItem.id} mode={currentItem.mode} userID={currentItem.userID} refreshList={refreshList}></TicketRecipientResultsChild>
        </div>
        );
    }   

    else if (currentItem.mode == 'new') {
        return (
        <div> 
            <SurveyDefinitionChild id={currentItem.id} mode={currentItem.mode} userID={currentItem.userID} refreshList={refreshList}></SurveyDefinitionChild>
        </div>
        );
    } 
    else if (currentItem.mode == 'loginfail') {
        return (
        <div> 
            <LoginFail></LoginFail>
        </div>
        );
    } 




    else if (currentItem.mode != 'hidden') {
        return (
        <div> 
            <SurveyDefinitionChild 
                id={currentItem.id} 
                mode={currentItem.mode} 
                userID={currentItem.userID} 
                refreshList={refreshList}
            ></SurveyDefinitionChild>
        </div>
        );
    }
    else {
        //shows the list of items
        return (
            <div className='width100pct'>
                <table width='100%'>
                    <tbody>
                        <tr>
                            <td className='leftAlign' ><h2>Survey Definitions</h2></td>
                            {/* <td className='leftAlign' >
                                User: <span>{state.user.emailAddress}</span>
                            </td> */}
                            <td className='rightAlign'>
                            
                                <button onClick={openScheduleList}><MdSchedule/></button>
                                &nbsp;
                                <button onClick={newSurveyHandler}><MdLibraryAdd/></button>
                                </td>
                        </tr>
                    </tbody>
                </table>
                
                
                <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                    <th></th>
                    <th>Survey Name</th>
                    {state.user && state.user.pID == 0 &&
                    <th>Portal Name</th>
                    }
                    <th>Schedule Name</th>
                    {state.user && state.user.roleType.indexOf('SurveyAnswers') > 1 &&
                    <th># Surveys Sent</th>
                    }
                    {state.user && state.user.roleType.indexOf('SurveyAnswers') > 1 &&
                    <th># Responses</th>
                    }
                    </tr>
                </thead>
                <tbody>
                {ar.map((rec: SurveyDefinitionRec) =>
                
                    <tr key={rec.id}>
                        <td>
                            <button onClick={event => selectSurveyHandler(event, rec.id)} title='Edit Survey Definition'><MdBuild color="green"/></button>
                        </td>
                        <td>
                            <button onClick={event => openSurveyReactHost(event, rec.aliasID)} title='Open and take survey'><MdCallMade color="red"/></button>
                            &nbsp;{rec.surveyName}
                        </td>
                        {state.user && state.user.pID == 0 &&
                        <td>{rec.portalName}</td>
                        }
                        <td>{rec.scheduleName}</td>
                        {state.user && state.user.roleType.indexOf('SurveyAnswers') > 1 &&
                        
                        <td>
                            <h3><button onClick={event => viewTicketRecipientResultsHandler(event, rec.id)} title='View ticket survey recipients'><MdOutlineMarkEmailUnread/></button>
                            &nbsp;{rec.numSurveysSent}
                            </h3>
                        </td>
                        }
                        {state.user && state.user.roleType.indexOf('SurveyAnswers') > 1 &&
                        <td>
                            <h3><button onClick={event => viewSurveyResponsesHandler(event, rec.id)} title='View survey responses in table form'><MdTableRows/></button>
                            <button onClick={event => analyzeSurveyHandler(event, rec.id)} title='Analyze survey responses in charts'><MdBarChart/></button>
                            &nbsp; {rec.numRecs}
                            </h3>
                        </td>
                        }
                    </tr>

                )}
                </tbody>
                </table>
                <hr></hr>
            </div>
        );
    }

}

function GetJsonResult(url: any) {
    //NOTE: this should return an array of objects
    var result;
    $.ajax({
        method: "GET",
        async: false,
        url: url,
        dataType: "json"
    })
        .done(function (msg) {
            result = msg;
            return result;
        });

    return result;
}

// Shorthand for $( document ).ready()
$(function() {
    // //"?id=A3831F4B-EBBE-4F05-BF75-546AC0B37CD2&codeid=9C83BB85-3B64-4C94-A3B3-EA9837B16EB0"
    // const path = window.location.search; //.pathname;
    // if (path.length > 0) {
    //     var userID =  getParameterByName("id",  window.location.href);
    //     var codeID =  getParameterByName("codeid",  window.location.href);

        
    //     var url = buildUrlReplace(_baseUrl, "{id}", userID);
    //     var url2 = buildUrlReplace(url, "{codeid}", codeID);
    //     var result = GetResult(url2);
    //     if (result == '00000000-0000-0000-0000-000000000000') {
    //         alert('SurveyDefinitions.tsx - not a valid user/code');
    //         window.Response.redirect("https://sandbox.cloudcover.it/login?url=");            
    //     }

    // }
    // else {
    //     //TODO: have redirect vary per env
    //     alert('SurveyDefinitions.tsx - no params passed in');
    //     window.Response.redirect("https://sandbox.cloudcover.it/login?url=");
    // }
});

function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function buildUrlReplace(url: any, searchFor: any, replaceWith: any) {
    var s = url;
        s = s.replace(searchFor, replaceWith)
    return s;
}

function GetResult(url: string) {
    var result = "";
    $.ajax({
        method: "GET",
        async: false,
        url: url
    })
        .done(function (msg) {
            result = msg;
            return result;
        });

    return result;
}




export default connect()(SurveyDefinitionsList as any);

import React, { Component } from "react";
import $ from "jquery";

import * as SurveyAnalytics from "survey-analytics";
import "survey-analytics/survey.analytics.css";

import * as SurveyAnalyticsTabulator from "survey-analytics/survey.analytics.tabulator";
import "tabulator-tables/dist/css/tabulator.css";
import "survey-analytics/survey.analytics.tabulator.css";

import { Model } from "survey-core";
import { MdClose } from "react-icons/md";




//sample from https://codesandbox.io/s/u5y4pn?file=/src/SurveyAnalyticsComponent.jsx:0-1272

const creatorOptions = {
    haveCommercialLicense: true //Add this line
   
};

//close button handler
const closeSurveyHandler = (event) => {
    event.preventDefault();
    console.log('close survey button clicked');
    //alert('close survey button clicked');
    _props.refreshList('close survey responses analyze button clicked');
  };

  
const buildUrlReplace = (url, searchFor, replaceWith) => {
    var s = url;
    s = s.replace(searchFor, replaceWith)
    return s;
}

var _props;
class SurveyAnalyticsComponent extends Component {
    constructor(props) {
        _props = props;
        super();

    }

    handleChange(e) {
        setName(e.target.value);
    }

          //For props, you use componentDidUpdate
    componentDidUpdate(prevProps){
        //You must have an if check, or loop forever
        if (this.props.id !== this.prevProps.id){
            //do something like make an API call
            //perhaps set this on state for display
            _props = this.props;
        }
   
    }

    componentDidMount() {
        var baseUrl = process.env.REACT_APP_SURVEY_FUNCTIONCODE_GETDEFINITION;
        var url = buildUrlReplace(baseUrl, "{id}", _props.id);

        $.ajax({
            method: "GET",
            async: false,
            url: url, 
            dataType: "json"
            })
            .done(function (defn) {
                //this is the survey definition
                var survey = new Model(defn.surveyDetail);
                survey.haveCommercialLicense = 'true';
                var surveyResultNode = document.getElementById("surveyResult");
                surveyResultNode.innerHTML = "";
                //these are the survey responses
                baseUrl = process.env.REACT_APP_SURVEY_FUNCTIONCODE_GETRESPONSES;
                url = buildUrlReplace(baseUrl, "{id}", _props.id);
                $.ajax({
                    method: "GET",
                    async: false,
                    url: url, 
                    dataType: "json"
                    })
                    .done(function (data) {
                        var visPanel = new SurveyAnalytics.VisualizationPanel(
                            survey.getAllQuestions(),
                            data.Data,
                            { haveCommercialLicense: true,
                                labelTruncateLength: 27 }
                        );
                        visPanel.showHeader = true;
            
                        $("#loadingIndicator").hide();
            
                        visPanel.render(surveyResultNode);
                    });
            })
    }

    render() {
        return (
            <div>
                <table className="width100pct">
                    <tbody>
                        <tr>
                            <td>
                                <h2>Survey Response Analysis</h2>
                            </td>
                            <td className="rightAlign">
                                <button onClick={closeSurveyHandler}><MdClose/></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div id="surveyResult"></div>

                <hr></hr>
                <div id="surveyDefinition"></div>



            </div>
        );
    }



}

export default SurveyAnalyticsComponent;
